import { Chip, Grid, Hidden } from "@mui/material";
import { IDropdownOption, IHeader, IList, ISort, ITable, ITableData } from "common/interfaces";
import _, { debounce } from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ArrowCircleDown, ArrowCircleUp } from "iconsax-react";
import { displayLoadingBar, getQueryString, hideLoadingBar, preventTextSelection, removeQueryStrings } from "utils/helpers";
import styles from "./Table.module.scss";
import InfiniteScroll from "./InfiniteScroll";
import { Button, ErrorBoundary, SVG, StatusLabel } from "common";
import { NewDropdown, SelectDropdown } from "common/form";
import React from "react";
import Log from "classes/Log";

export interface TableRef {
  reloadData: () => void;
  listData: any;
}

/** 
 * @summary A responsive table that adapts to the size screen and infinite scroll built in.
 * @param {ITable} props ITable props.
 * @returns Table component.
 * */
const Table: React.ForwardRefRenderFunction<TableRef, ITable> = ({ id, data, action, headers, onUpdate, onLoadingRows,
  tableWrapperStyle, headerWrapperStyle, tableBodyStyle, supportMobile, keyword, selectedItemId, maxHeight, onSort, disabled, smallHeader, minDropdownWidth, showEmptyTable }: ITable, ref) => {

  const [tableDataRows, setTableDataRows] = useState<Array<any>>([]);
  const [tableDataHeaders, setTableDataHeaders] = useState<Array<IHeader>>(headers);
  const [sortedColumn, setSortedColumn] = useState<ISort>({ key: null, asc: false });
  const [listData, setListData] = useState<IList>(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    // this useEffect is most important inside of headers object change - for Dashboard table with SelectDropdown as the first header field
    setTableDataHeaders(headers);
  }, [headers]);

  useEffect(() => {
    if (tableDataRows[0]?.selected !== undefined && tableDataRows[0]?.id !== undefined) {
      setTableDataRows(
        tableDataRows?.map((item) => {
          return {
            ...item,
            selected: item.id === selectedItemId
          }
        })
      );
    }
  }, [selectedItemId]);

  keyword = keyword || "";

  const totalNumColumns = _.sumBy(headers, header => { return header.size || 1 });

  useEffect(() => {
    requestData(null); // - Initial load is always null
  }, []);

  useEffect(() => {
    if (onUpdate) {
      onUpdate(tableDataRows);
    }
  }, [tableDataRows]);

  /**
   * @description Requests data from a server and sets the resulting data to state.
   * @param {string} next - Link to load data.
   */
  const requestData = (next: string): void => {
    displayLoadingBar();
    setDataLoaded(false);
    data(next)?.then(result => {
      setListData(result);
      if (result?.previous) {
        setTableDataRows(_.concat(tableDataRows, result.results));
      } else {
        setTableDataRows(result?.results);// looks like this isn't always called, or the other setTableData calls overruns it or something
      }
      setDataLoaded(true);
      if (result?.x_progress !== null && result?.x_progress < 100) {
        // offers, that are still loading 
      } else {
        hideLoadingBar();
      }
    })
      .catch(error => {
        Log.error(error);
        hideLoadingBar();
      });
  }

  /** 
   * @summary Event triggered when the user reaches the end of the scrollbar.
   * */
  const onScrollEnd = (): void => {
    if (listData?.next) {
      requestData(listData.next);
      if (onLoadingRows) {
        onLoadingRows();
      }
    }
  }

  const resetTable = () => {
    const tableDiv = window.document.getElementById(`${id}_table_infiniteScroll`);
    if (tableDiv) {
      tableDiv.scrollTop = 0;
    }
  }

  useEffect(() => {
    if ((keyword || keyword === "") || sortedColumn.key) {
      // Query API
      resetTable();
      let queryStringsToRemove = ["limit", "offset", "search"];
      if (sortedColumn.key) {
        queryStringsToRemove.push("ordering");
      }
      const currentQuerystring = getQueryString(listData?.next);
      const header = _.find(headers, header => sortedColumn.key === header.value);
      const ordering = sortedColumn.key ? `${!sortedColumn.asc ? "-" : ""}${header?.sortBy || header.value}` : "";

      let newNext = listData?.next || listData?.originalUrl;
      if (newNext) {
        newNext = removeQueryStrings(newNext, queryStringsToRemove);
        newNext = `${newNext}limit=${currentQuerystring.limit || "30"}&offset=0&search=${keyword}${ordering ? "&ordering=" : ""}${ordering}`;
        requestData(newNext);
      }

      if (sortedColumn?.key && onSort) {
        onSort(ordering);
      }
    }
  }, [sortedColumn, keyword]);

  /** 
   * @summary Builds the action column.
   * @param {any} item Json object.
   * @param {number} index Item index.
   * @returns Action element.
   * */
  const getAction = (item: any, index: number) => {
    if (action && typeof action === "function") {
      return <Button
        id={`table_${id}_button_${index}`}
        label={item.selected ? "Selected" : "Select"}
        variant={item.selected ? "primary" : "secondary"}
        style={{ marginTop: 6, maxWidth: 120, marginRight: 15, padding: "0.9rem 0" }}
        onClick={() => {
          action(item);
        }}
      />;
    }
    else if (action && typeof action === "object") {
      let options = action as Array<IDropdownOption>;
      options = _.filter(options, option => { return option.visible(item) });

      return <SelectDropdown
        selectOptions={_.sortBy(options.map((option: any) => { return { label: option.label, value: option.label }; }), ["label"])}
        id={`table_${id}_actions_${index}`}
        onChange={(value: string) => {
          const selectedAction = options.find(option => {
            return option.label === value;
          });
          selectedAction?.value(item);
          requestData(null); // Initial load
        }}
        insideTable={true}
        minWidth={minDropdownWidth}
        placeholder="Actions"
      />
    }
    else { return <></>; }
  }

  const renderHeader = () => {
    return <div style={headerWrapperStyle}>
      <Grid className={styles.tableHeader + (smallHeader ? " " + styles.smallHeader : "")} container spacing={1} direction="row" columns={totalNumColumns} wrap="wrap" style={{ minWidth: tableBodyStyle?.minWidth || "inherit" }}>
        {tableDataHeaders.map((header: IHeader, headerIndex: number) => {
          if (header.onChange) {
            return <Grid
              key={`header${headerIndex}`}
              item
              onClick={header.preventSorting ? () => { } : () => setSortedColumn({ key: header.value, asc: sortedColumn.asc ? !sortedColumn.asc : true })}
              onMouseDown={preventTextSelection}
              className={styles.tableHeaderRow}
              xs={header.size || 1}
              style={{ textAlign: header.align || "left", cursor: header.preventSorting ? "default" : "pointer" }}>
              <div style={{ display: "flex", gap: 10, justifyContent: (header.align === "right" ? "flex-end" : (header.align === "left" ? "flex-start" : header.align)) }}>
                <SelectDropdown id="table-type" selectOptions={header.options} onChange={header.onChange} value={header.label.toString().toLowerCase()} insideTableHeader={true} minWidth={120} />
              </div>
            </Grid>
          } else {
            return <Grid
              key={`header${headerIndex}`}
              item
              onClick={header.preventSorting ? () => { } : () => setSortedColumn({ key: header.value, asc: sortedColumn.asc ? !sortedColumn.asc : true })}
              onMouseDown={preventTextSelection}
              className={styles.tableHeaderRow}
              xs={header.size || 1}
              style={{ textAlign: header.align || "left", cursor: header.preventSorting ? "default" : "pointer", lineHeight: "4rem" }}>
              <div style={{ display: "flex", gap: 10, justifyContent: (header.align === "right" ? "flex-end" : (header.align === "left" ? "flex-start" : header.align)) }}><div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={header.label.toString()}>{header.label}</div>{sortedColumn.key !== header.value || header.preventSorting ? <></> : <div>{sortedColumn.asc ? <ArrowCircleDown size={18} /> : <ArrowCircleUp size={18} />}</div>}</div>
            </Grid>
          }
        })}
      </Grid>
    </div>;
  }

  const renderContent = () => {
    const tableContent = <div className={styles.tableContainer + (disabled ? " " + styles.disabled : "")} id={`${id}_table`} style={tableWrapperStyle}>
      {renderHeader()}
      <InfiniteScroll onScrollEnd={onScrollEnd} styleOverride={{ ...tableBodyStyle, maxHeight }} id={`${id}_table_infiniteScroll`}>
        <Grid className={styles.rowWrapper} container spacing={1} direction="row" columns={totalNumColumns} wrap="wrap">
          {tableDataRows?.map((row: any, rowIndex: number) => {
            if (!row)
              return;
            let classNamePrimary = "";
            if (row.highlight?.highlighted) {
              classNamePrimary = styles.highlighted;
            }
            return tableDataHeaders?.map((header: any, headerIndex: number) => {
              return <Grid
                key={`header${headerIndex}_row${rowIndex}`}
                item
                className={`${header.value.toLowerCase() === "actions" ? styles.button : styles.rows} ${classNamePrimary}`}
                xs={header.size || 1}
                style={{ textAlign: header.align || "left" }}>
                {header.value.toLowerCase() === "actions" && action ? getAction(row, rowIndex) : (header.value === "status" ? <StatusLabel label={row[header.value]} /> : row[header.value])}
                {row.highlight?.highlighted && row.highlight?.chip && header.value === row.highlight?.property && <Chip size="small" label={row.highlight?.chip} style={{ backgroundColor: "var(--primaryColor)", color: "white", fontSize: "1.2rem", height: "25px", padding: "3px", marginLeft: "5px" }} />}
              </Grid>;
            })
          })}
        </Grid>
      </InfiniteScroll>
    </div>;

    if (!supportMobile) {
      return tableContent;
    } else {
      return <Hidden mdDown>
        {tableContent}
      </Hidden>;
    }
  }

  const reloadData = () => {
    const fn = debounce(() => {
      requestData(null);
    }, 100);
    fn();
  };

  // Expose the child function using the ref
  useImperativeHandle(ref, () => ({
    reloadData,
    listData
  }));

  return <>
    {tableDataRows?.length > 0 && <ErrorBoundary>
      {renderContent()}
      {supportMobile && <Hidden mdUp>
        <InfiniteScroll onScrollEnd={onScrollEnd} id={`${id}_table_infiniteScroll`}>
          {tableDataRows?.map((row: any, rowIndex: number) => {
            return <div className={styles.card} key={`row_card_${rowIndex}`}>
              {tableDataHeaders?.map((header: any, headerIndex: number) => {
                return <div key={`header${headerIndex}_row${rowIndex}`}>
                  <div className={styles.header}>{header.label}</div>
                  <p>{row[header.value]}</p>
                </div>
              })}
              {action && <div className={styles.box}>
                {getAction(row, rowIndex)}
              </div>}
            </div>;
          })}
        </InfiniteScroll>
      </Hidden>}
    </ErrorBoundary>}
    {/* don't show table at all */}
    {!showEmptyTable && dataLoaded && tableDataRows?.length === 0 && !headers[0]?.onChange && <div className={styles.emptyTableText + (disabled ? " " + styles.disabled : "")} style={tableWrapperStyle}>
      There is no data to display here.<br /><br /><br />
      {<SVG src={`/assets/dashboard/empty_funnel_offered.svg`} height={"100%"} />}
    </div>
    }
    {/* show only header - used in dashboards and offers table, when waiting for new offers */}
    {((dataLoaded && tableDataRows?.length === 0 && headers[0]?.onChange) || (showEmptyTable && tableDataRows?.length === 0)) &&
      <div className={styles.tableContainer + (disabled ? " " + styles.disabled : "")} id={`${id}_table`} style={tableWrapperStyle}>
        {renderHeader()}
        {!showEmptyTable && <div className={styles.emptyTableData + (disabled ? " " + styles.disabled : "")} style={tableWrapperStyle}>
          {<SVG src={`/assets/dashboard/empty_funnel_offered.svg`} height={"100%"} />}
        </div>}
      </div>
    }
  </>;
};

export default forwardRef(Table);
