import styles from "./Applications.module.scss";
import {
  Button, Table, Sidebar, DashboardHeader, ExportButton, Aside, Link,
  SearchBar, FilterButton, TimeZoneMessage
} from "common";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import appActions from "reducers/AppReducer";
import popUpActions from "reducers/PopUpReducer";
import { IFilter, IHeader, IHighlight, IList } from "common/interfaces";
import { useAuth } from "auth/useAuth";
import { OFFER_TYPE, POPUPS } from "utils/constants";
import { useParams } from "react-router-dom";
import { TableRef } from "common/Table";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import Observer, { EVENTS } from "classes/Observer";
import Analytics, { ITracking } from "classes/Analytics";
import { formatAmount, formatAPICurrency, formatAPIDate, formatAPIPhone, formatAPIString, formatFullname, stringAmountToNumber } from "utils/formatters";
import { useFlag } from "hooks/useFlag";
import ApplicationSummaryCard from "./ApplicationSummaryCard";
import InnerPopups from "content/popups/InnerPopups";
import { askForConfirmation, closePopup } from "utils/helpers";

const Applications: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAuth()?.user;
  const { merchantSlug } = useParams();
  const selectedLocation = useAppSelector(state => state.app.selectedLocation);
  const observerLoanAppUpdated = Observer.useObserver(EVENTS.LOAN_APP_UPDATED);
  const tableFilterAppliedObserver = Observer.useObserver(EVENTS.TABLE_FILTER_APPLIED);
  const applicationFilter = useAppSelector(state => state.app.tableFilters.application);
  const mockupEnabled = useFlag("special-mockups");
  const enableBusinessModel = useFlag("business-model");
  const observerBusinessModel = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);

  const [keyword, setKeyword] = useState("");
  const [tableRows, setTableRows] = useState([]);
  const tableRef = useRef<TableRef>(null);
  const [preventPagination, setPreventPagination] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [applicationId, setApplicationId] = useState<string>("");

  const applicationSummaryRef = useRef(null);

  const applicationsHeaders: Array<IHeader> = [
    { label: "Name", value: "name", size: 11, sortBy: "first_name" },
    { label: "Status", value: "status", size: 9 },
    { label: "Phone", value: "phone_number", size: 9 },
    { label: "Email", value: "email", size: 13 },
    { label: user?.user_type === "WHITELABEL" ? "Merchant" : "Location", value: "location", size: 12, sortBy: user?.user_type === "WHITELABEL" ? "merchant__name" : "location__name" },
    { label: "App date", value: "appdate", size: 8, sortBy: "created_at" },
    { label: "Requested", value: "requested", size: 8, sortBy: "loan_amount" },
    { label: "Funded", value: "funded", size: 8, sortBy: "funded_amount" },
  ];

  // // already prepared for applications table update story
  // const applicationsHeaders: Array<IHeader> = [
  //   { label: "Name", value: "name", size: 11, sortBy: "first_name" },
  //   { label: user?.user_type === "WHITELABEL" ? "Merchant" : "Location", value: "location", size: 12, sortBy: user?.user_type === "WHITELABEL" ? "merchant__name" : "location__name" },
  //   { label: "Status", value: "status", size: 8 },
  //   { label: "App date", value: "appdate", size: 8, sortBy: "created_at" },
  //   { label: "APR", value: "apr", size: 8, sortBy: "created_at" },
  //   { label: "Requested", value: "requested", size: 8, sortBy: "loan_amount" },
  //   { label: "Max offered", value: "max_offered", size: 8, sortBy: "max_offered" },
  //   { label: "Funded", value: "funded", size: 8, sortBy: "funded_amount" },
  // ];

  const handlePendingClick = async (item: any): Promise<void> => {
    const offersDetails = await getOfferDetails(item?.id);
    const offerDetails = offersDetails.results[0];

    askForConfirmation(<>
      <h1 style={{ marginBottom: 30 }}>{`Approve $${formatAmount(offerDetails?.amount)} loan for ${formatFullname(item?.first_name, item?.last_name)}?`}</h1>
      <h3 className={styles.confirmationDialog}><span className={`${styles.confirmationDialog} ${styles.primary}`}>${formatAmount(item?.loan_amount)}</span> requested amount</h3>
      <h3 className={styles.confirmationDialog}><span className={`${styles.confirmationDialog} ${styles.primary}`}>{offerDetails?.monthlyPayment}</span>/month for <span className={`${styles.confirmationDialog} ${styles.primary}`}>{offerDetails?.term}</span> {(offerDetails?.term_unit as string)?.toLowerCase()}s</h3>
      <h3 className={styles.confirmationDialog}><span className={`${styles.confirmationDialog} ${styles.primary}`}>{offerDetails?.apr_num}%</span>&nbsp;interest rate | <span className={`${styles.confirmationDialog} ${styles.primary}`}>{formatAmount(offerDetails?.dtm_extra_data?.discount_rate * 100 || 0)}%</span> discount rate</h3>
      <h3 className={styles.confirmationDialog}><span className={`${styles.confirmationDialog} ${styles.primary}`}>{offerDetails?.dtm_extra_data?.same_as_cash_length} months SAC</span>&nbsp;same as cash <span style={{ fontSize: 13 }}>(no interest if paid in full)</span></h3>
    </>,
      { text: "Approve", action: () => { approveOffer(item); closePopup(); } },
      { text: "Decline", action: () => { denyOffer(item); closePopup(); } }, false, 800);
  }

  const handleApplicationStatusClick = async (item: any): Promise<void> => {
    dispatch(popUpActions.openPopup({ name: POPUPS.APPLICATION_STATUS, message: item }));
  }

  const handleSetAmountRecalculateClick = async (item: any): Promise<void> => {
    dispatch(popUpActions.openPopup({ name: POPUPS.RECALCULATION, message: item }));
  }

  const approveOffer = async (item: any) => {
    await LoanApplicationHandler.approveOrDenyOffer(item?.id, "approve")
      .then(() => tableRef?.current?.reloadData());
  }

  const denyOffer = async (item: any) => {
    await LoanApplicationHandler.approveOrDenyOffer(item?.id, "decline")
      .then(() => tableRef?.current?.reloadData());
  }

  const getOfferDetails = async (applicantId): Promise<IList> => {
    let offerDetails = await LoanApplicationHandler.getOfferDetails(null, applicantId);

    offerDetails.results = offerDetails.results.map(result => {
      return {
        ...result,
        lender: result.lender_name,
        dateTime: formatAPIDate(result.date_time),
        offerType: result.pre_approved ? OFFER_TYPE.PRE_APPROVED : (result.pre_qualified ? OFFER_TYPE.PRE_QUALIFIED : "-"),
        termOfLoan: `${result.term} ${result.term_unit?.toLowerCase()}${result.term > 1 ? 's' : ''}`,
        monthlyPayment: formatAPICurrency(parseFloat(result.monthly_payment)),
        maxLoan: formatAPICurrency(parseFloat(result.amount)),
        apr: `${parseFloat(result.apr).toFixed(2)}% ${result.apr_type}`,
        monthly_payment: parseFloat(result.monthly_payment),
        max_loan: parseFloat(result.amount),
        apr_num: parseFloat(result.apr),
        status: result.status,
        funded_amount: parseFloat(result.funded_amount || "0"),
        fundedAmount: formatAPICurrency(parseFloat(result.funded_amount || "0")),
      }
    });
    return offerDetails;
  }

  const handleShowSummary = (item) => {
    setApplicationId(item.id);
    Analytics.track({ experience: "portal", screen: "applications", object: "application_record", action: "selected" } as ITracking, { application_id: applicationId, merchant_id: item.merchant?.id });
  }

  const getButtonStyle = (status: string): CSSProperties => {
    const color = status === "Pending with Merchant" ? "var(--primaryColor)" : "var(--primaryVariationColor)";
    return {
      width: "100%",
      backgroundColor: color,
      borderColor: color
    };
  }

  const getStatus = (item: any) => {
    /* status "Pending with Merchant" indicates that application has come from a landing page and the application is waiting for merchant to do something with it
    either approving application with Special(if auto - approval is off ?), and recalculation for all merchants */
    if (user?.user_type === "MERCHANT" && mockupEnabled && item?.status === "Pending with Merchant") {
      return <Button onClick={() => handleSetAmountRecalculateClick(item)} label="Set amount" id={item?.id} style={getButtonStyle(item?.status)} small>Set amount</Button>;
    }
    // status Offered means that application amount came from a landing page, but amount was already set or application came from the invitation - will be used in the future
    // if (user?.user_type === "MERCHANT" && mockupEnabled && item?.status === "Offered") {
    //   return <Button onClick={() => handleSetAmountRecalculateClick(item)} label="Recalculate" id={item?.id} style={getButtonStyle(item?.status)} small>Recalculate</Button>;
    // }
    if (user?.user_type === "MERCHANT" && mockupEnabled && item?.status === "Delinquent") {
      return <Button onClick={() => handleApplicationStatusClick(item)} label="Delinquent" id={item?.id} style={getButtonStyle(item?.status)} small>Delinquent</Button>;
    }
    if (user?.user_type === "MERCHANT" && mockupEnabled && item?.status === "Active Dispute") {
      return <Button onClick={() => handleApplicationStatusClick(item)} label="Dispute" id={item?.id} style={getButtonStyle(item?.status)} small>Dispute</Button>;
    }
    if (user?.user_type === "MERCHANT" && mockupEnabled && item?.status === "Unable to Verify") {
      return <Button onClick={() => handleApplicationStatusClick(item)} label="Unable to Verify" id={item?.id} style={getButtonStyle(item?.status)} small>Unverified Applicant</Button>;
    }
    if (user?.user_type === "MERCHANT" && mockupEnabled && item?.status === "Potential Buyback") {
      return <Button onClick={() => handleApplicationStatusClick(item)} label="Potential Buyback" id={item?.id} style={getButtonStyle(item?.status)} small>Potential Buyback</Button>;
    }

    return item?.status;
  }

  const getApplicationsData = async (next: string): Promise<IList> => {
    setLoadingData(true);
    const appFilter: IFilter = {
      ...applicationFilter,
      merchant: applicationFilter?.merchant || merchantSlug,
      location: selectedLocation?.id || null
    };
    let list = await LoanApplicationHandler.getMany(next, appFilter, preventPagination, null, true);
    const newList = {
      ...list,
      results: list.results.map(item => {
        return {
          ...item,
          name: <button className={styles.applicantName} onClick={() => { handleShowSummary(item) }}>{formatFullname(item?.first_name, item?.last_name)}</button>,
          phone_number: formatAPIPhone(item.phone_number),
          email: formatAPIString(item.email),
          location: user?.user_type === "WHITELABEL" ? <Link id={`viewMerchant_${item.merchant?.slug}`} href={`/viewMerchant/${item.merchant?.slug}/accountInformation`} linkText={formatAPIString(user?.user_type === "WHITELABEL" ? item.merchant?.name : item.location?.name)} /> : item.location?.name,
          status: getStatus(item),
          appdate: formatAPIDate(item.created_at),
          requested: formatAPICurrency(item.loan_amount),
          funded: formatAPICurrency(item.funded_amount),
          highlight: { highlighted: mockupEnabled && item?.status === "Pending with Merchant", property: "name" } as IHighlight,
          external_id: item?.external_id || ""
        }
      })
    };
    setLoadingData(false);
    // console.log("newList", newList);
    return Promise.resolve(newList);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (applicationSummaryRef.current && !applicationSummaryRef.current.contains(e.target)) {
      handleCloseApplicationSummary();
    }
  };

  const handleCloseApplicationSummary = () => {
    setApplicationId("");
  }

  useEffect(() => {
    if (enableBusinessModel && observerBusinessModel > 1) {
      window.location.reload();
    }
  }, [observerBusinessModel]);

  useEffect(() => {
    dispatch(appActions.updateApplicationFilter({ ...applicationFilter, location: selectedLocation?.id || null }));
  }, [selectedLocation]);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [applicationFilter, observerLoanAppUpdated, tableFilterAppliedObserver, mockupEnabled]);

  useEffect(() => {
    if (preventPagination) {
      tableRef?.current?.reloadData();
    }
  }, [preventPagination]);

  useEffect(() => {
    if (preventPagination) {
      window.DownloadCSV(tableRows, [...applicationsHeaders, { label: "External ID", value: "external_id" }] as Array<any>, "Applications.csv");
      setPreventPagination(false);
    }
  }, [tableRows]);

  useEffect(() => {
    if (keyword) {
      Analytics.track({ experience: "portal", screen: "applications", object: "search", action: "initiated" } as ITracking);
    }
  }, [keyword]);

  const exportAction = () => {
    setPreventPagination(true);
  }

  const renderBody = () => {
    const body = <div className={styles.contentContainer}>
      {user?.user_type === "MERCHANT" && <Container fluid>
        <Row>
          <Col md={12} lg={7} className={styles.filterColOne}>
            <Button
              id="applications_send"
              label="Invite applicant"
              style={{ marginRight: "3.5rem" }}
              onClick={() => {
                Analytics.track({ experience: "portal", screen: "applications", object: "invite_applicant_button", action: "clicked" } as ITracking);
                dispatch(popUpActions.openPopup(POPUPS.INVITE_APPLICANT));
              }}
            />
            <Button
              id="applications_invitationStatus"
              label="Invitation status"
              variant="secondary"
              onClick={() => {
                Analytics.track({ experience: "portal", screen: "applications", object: "invitation_status_button", action: "clicked" } as ITracking);
                dispatch(popUpActions.openPopup({ name: POPUPS.INVITATION_STATUS, message: "APPLICATIONS" }));
              }}
            />
          </Col>
          <Col
            md={12}
            lg={5}
            style={{ textAlign: "right" }}
            className={styles.appBtnColTwo}>
            <ExportButton
              id="applications"
              exporting={preventPagination}
              disabled={loadingData}
              onClick={exportAction} />
          </Col>
        </Row>
      </Container>}
      <Container fluid className={styles.searchContainer}>
        <Row>
          <Col md={6} lg={6} className={styles.searchColOne}>
            <SearchBar
              id="application_search"
              placeholder="Search applicants"
              onSearch={(keyword: string) => { setKeyword(keyword); }}
              onClearClick={() => {
                Analytics.track({ experience: "portal", screen: "applications", object: "clear_search", action: "clicked" } as ITracking);
              }}
            />
          </Col>
          <Col md={6} lg={6} className={styles.searchColTwo}>
            <FilterButton
              id="application_filter"
              type="APPLICATION"
              onClick={() => {
                dispatch(popUpActions.openPopup({ name: POPUPS.TABLE_FILTER, message: { type: "APPLICATIONS" } }));
              }} />
            {user?.user_type === "WHITELABEL" && <ExportButton
              id="applications"
              exporting={preventPagination}
              disabled={loadingData}
              onClick={() => {
                exportAction();
                Analytics.track({ experience: "portal", screen: "applications", object: "export_button", action: "clicked" } as ITracking);
              }} />}
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Table
          id="applications_location"
          data={getApplicationsData}
          headers={applicationsHeaders}
          onUpdate={(rows: any) => { setTableRows(rows) }}
          tableBodyStyle={{ minWidth: 1100 }}
          headerWrapperStyle={{ minWidth: 1100 }}
          keyword={keyword}
          ref={tableRef}
          maxHeight={450}
          onLoadingRows={() => {
            Analytics.track({ experience: "portal", screen: "applications", object: "table_load_more_rows", action: "initiated" } as ITracking);
          }}
          onSort={(field_sorted: string) => {
            Analytics.track({ experience: "portal", screen: "applications", object: "table_sort", action: "successful" } as ITracking, { field_sorted });
          }}
        />
      </Container>
      <TimeZoneMessage />
      <ApplicationSummaryCard ref={applicationSummaryRef} applicantId={applicationId} closeApplicationSummary={handleCloseApplicationSummary} active={applicationId ? true : false} />
    </div>;
    if (!merchantSlug) {
      return <main className={styles.applicationsPageContainer}>
        <Sidebar />
        <Aside>
          <DashboardHeader />
          <InnerPopups />
          {body}
        </Aside>
      </main>;
    } else {
      return <div style={{ paddingTop: 100, paddingLeft: 80 }}>{body}</div>;
    }
  }

  return renderBody();
};

export default Applications;
