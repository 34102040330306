import LocalStorage from 'classes/LocalStorage';
import Observer, { EVENTS } from 'classes/Observer';
import { IUser } from 'common/interfaces';
import { BusinessModelType } from 'common/types';
import { useEffect, useState } from 'react';
import { useFlag } from './useFlag';
import useMerchant from './useMerchant';

export const useBusinessModel = () => {
  const [selectedBusinessModel, setSelectedBusinessModel] = useState<BusinessModelType>(LocalStorage.get<string>("business_model") as BusinessModelType);
  const businessModelUpdated = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);
  const merchant = useMerchant();
  const enableDtmDtcToggle = useFlag("dtm-dtc-toggle");
  const user = LocalStorage.get<IUser>("user");

  // set default businessModel in case it's not set
  useEffect(() => {
    if (selectedBusinessModel === null) {
      if (enableDtmDtcToggle) {
        if (user?.user_type === "MERCHANT") {
          // merchants
          if ((merchant?.programs as Array<string>).includes("DTM") && selectedBusinessModel !== "DTM") {
            LocalStorage.save("business_model", "DTM");
            setSelectedBusinessModel("DTM");
            Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
          } else if ((merchant?.programs as Array<string>).includes("DTC") && selectedBusinessModel !== "DTC") {
            LocalStorage.save("business_model", "DTC");
            setSelectedBusinessModel("DTC");
            Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
          }
        } else if (user?.user_type === "WHITELABEL") {
          // whitelabels - automatically expect both DTC and DTM
          LocalStorage.save("business_model", "DTM");
          if (selectedBusinessModel !== "DTM") {
            setSelectedBusinessModel("DTM");
          }
          Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
        } else if (user?.user_type === "PARTNER") {
          // partners - same as whitelabels for now
          LocalStorage.save("business_model", "DTM");
          if (selectedBusinessModel !== "DTM") {
            setSelectedBusinessModel("DTM");
          }
          Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
        }
      } else if (!enableDtmDtcToggle && selectedBusinessModel !== "All") {
        LocalStorage.save("business_model", "All");
        setSelectedBusinessModel("All");
        Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
      }
    }
  }, [selectedBusinessModel]);

  useEffect(() => {
    setSelectedBusinessModel(LocalStorage.get<string>("business_model") as BusinessModelType);
  }, [businessModelUpdated]);

  return selectedBusinessModel;
};