import React from "react";
import styles from "./FeedbackPopup.module.scss";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IFeedback, IPopup } from "common/interfaces";
import { Button, CloseIcon, Grid } from "common";
import {
  Gears, Hands, Hourglass, Lock, MerchantHouse, Spaceship,
  Unplugged, UserSVG, Baloons, Success, Cancel,
} from "content/popups/feedback";
import { getVisitedPageBeforeNotFound } from "utils/helpers";
import { useAuth } from "auth/useAuth";
import popUpActions from "reducers/PopUpReducer";
import { useKeyDown } from "hooks/useKeyDown";
import Popup from "../Popup";

const FeedbackPopup: React.FC<IPopup> = () => {
  const { title, body, disclaimer, type, buttons, hideCloseButton }: IFeedback = useAppSelector(state => state.popUp.feedbackPopup);
  const lastVisitedPage = getVisitedPageBeforeNotFound();
  const user = useAuth()?.user;
  const dispatch = useAppDispatch();
  const keyDownEvent = useKeyDown(!buttons);

  const renderBackground = () => {
    switch (type) {
      case "UNPLUGGED":
        return <Unplugged />;
      case "RESTRICTED":
        return <Lock />;
      case "HOURGLASS":
        return <Hourglass />;
      case "UFO":
        return <Spaceship />;
      case "GEARS":
        return <Gears />;
      case "MERCHANT":
        return <MerchantHouse />;
      case "PARTNER":
        return <Hands />;
      case "USER":
        return <UserSVG />;
      case "BALOONS":
        return <Baloons />;
      case "SUCCESS":
        return <Success />;
      case "CANCEL":
        return <Cancel />;
      case "NO_IMAGE":
        return null;
      default:
        return null;
    }
  }

  const handleClose = () => {
    if (window.location.pathname === "/notFound") {
      window.location.href = lastVisitedPage || '/dashboard';
    }
    dispatch(popUpActions.hideFeedbackPopup());
  }

  if (!buttons) {
    if (keyDownEvent?.code === "Enter" || keyDownEvent?.code === "NumpadEnter") {
      keyDownEvent.preventDefault();
      handleClose();
    }
  }

  return <>
    <Popup>
      <>
        {!hideCloseButton &&
          <CloseIcon id="feedbackPopup" color="dark" />
        }
        <div className={styles.popupInfo}>
          <div className={styles.title}>{title}</div>
          <div className={styles.body}>
            <>
              {body?.split('\n').map((str, id) => <p key={id}>{str}</p>)}
              {disclaimer?.split('\n').map((str, id) => <p key={id} className="info">{str}</p>)}
              <div style={{ marginTop: 30 }}>{renderBackground()}</div>
              {buttons && <Grid horizontal>
                {buttons.map((button, index) => {
                  return <Grid item key={`feedback_popup_button${index}`}>
                    <div className={styles.submitBtn} >
                      <Button id={button.id} label={button.label} onClick={button.action} variant={button.secondary ? "secondary" : "primary"} style={{ maxWidth: 150, marginTop: type === "BLANK" ? 20 : 0 }} />
                    </div>
                  </Grid>;
                })}
              </Grid>}
              {!buttons && <Button id="feedback_close" label="Close" style={{ position: "relative", maxWidth: 150, marginTop: type === "BLANK" ? 20 : 0 }} onClick={handleClose} />}
            </>
          </div>
        </div>
      </>
    </Popup>
  </>;
};

export default FeedbackPopup;
